<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.name" back="Organisations.Signups" :readonly="!isPending">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving && !is.approving && !is.rejecting" :disabled="!isDirty || !isValid || model.used || is.approving || is.rejecting" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />
		<app-content-head-actions-item v-if="isPending" :divide="true" :loading="is.approving" :disabled="is.saving || is.rejecting" icon="approve" text="Approve" v-on:click="onApproveClick" />
		<app-content-head-actions-item v-if="isPending" :loading="is.rejecting" :disabled="is.saving || is.accepting" icon="reject.thumb" text="Reject" v-on:click="onRejectClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box title="Admin Notes" icon="admin.team">

			<app-input-text :disabled="!isPending" label="Shared Reason" :autogrow="true" placeholder="Enter reason for your decision that will be passed on to the user at the time of approval or rejection." v-model="model.decision.share" :validation="$v.model.decision.share" />
			<app-input-text label="Private Notes" :autogrow="true" placeholder="Enter private notes relating to this sign-up that will not be shared with the user." v-model="model.decision.notes" :validation="$v.model.decision.notes" />

		</app-content-box>

		<app-content-box title="Sign-up Details" icon="organisation" :readonly="!isPending">

			<app-input-text label="Firstname" placeholder="Enter firstname" v-model="model.user.firstname" :validation="$v.model.user.firstname" />
			<app-input-text label="Lastname" placeholder="Enter lastname" v-model="model.user.lastname" :validation="$v.model.user.lastname" />
			<app-input-text label="E-mail" placeholder="Enter email" v-model="model.user.email" :validation="$v.model.user.email" />

			<app-content-divide />

			<app-input-text label="Organisation" placeholder="Enter name" v-model="model.organisation.name" :validation="$v.model.organisation.name" />
			<app-input-toggle label="Currency" v-model="model.organisation.currency" :options="currencyOptions" :validation="$v.model.organisation.currency" />

			<app-content-divide />

			<app-input-text label="Convention" placeholder="Enter name" v-model="model.convention.name" :validation="$v.model.convention.name" />
			<app-input-date label="Start" placeholder="Enter date" v-model="model.convention.date_start" :validation="$v.model.convention.date_start" />
			<app-input-date label="End" placeholder="Enter date" v-model="model.convention.date_end" :validation="$v.model.convention.date_end" />
			<app-input-text label="Website" placeholder="Enter website" v-model="model.convention.website" :validation="$v.model.convention.website" />
			<app-input-text label="Estimated Attendance" placeholder="Enter estimate" v-model="model.convention.attendance" :validation="$v.model.convention.attendance" />
			<app-input-text label="Additional Info" :autogrow="true" placeholder="Enter additional info" v-model="model.convention.info" :validation="$v.model.convention.info" />

			<app-content-divide />

			<app-input-toggle label="Registration" v-model="model.convention.service.registration" :validation="$v.model.convention.service.registration" :options="service" />
			<app-input-toggle label="Schedule" v-model="model.convention.service.schedule" :validation="$v.model.convention.service.schedule" :options="service" />
			<app-input-toggle label="Checkout" v-model="model.convention.service.checkout" :validation="$v.model.convention.service.checkout" :options="service" />
			<app-input-toggle label="Library" v-model="model.convention.service.library" :validation="$v.model.convention.service.library" :options="service" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { email } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			currencyOptions: {
				1: 'USD',
				2: 'CAD'
			},
			is: {
				approving: false
			},
			service: {
				0: 'No',
				1: 'Yes'
			},
			model: {
				id: false,
				status: 0,
				user: {
					firstname: '',
					lastname: '',
					email: ''
				},
				decision: {
					share: '',
					notes: ''
				},
				organisation: {
					currency: 0,
					name: ''
				},
				convention: {
					name: '',
					website: '',
					info: '',
					attendance: '',
					date_start: 0,
					date_end: 0,
					service: {
						registration: 0,
						schedule: 0,
						library: 0,
						checkout: 0
					}
				}
			}
		}

	},

	validations: {
		model: {
			user: {
				email: {
					email
				}
			},
			decision: {},
			organisation: {},
			convention: {
				service: {}
			}
		}
	},

	computed: {

		isPending: function() {

			return this.item.status === this.$constants.admin.signup.status.pending

		}

	},

	methods: {

		onSaved: function() {

			if (this.is.approving) {

				this.$router.push({
					name: 'Organisations.Signups'
				})

			}

		},

		onApproveClick: function() {
			
			this.is.approving = true

			this.model.status = this.$constants.admin.signup.status.approved

			this.onSaveClick()

		},

		onRejectClick: function() {
			
			this.is.approving = true

			this.model.status = this.$constants.admin.signup.status.rejected

			this.onSaveClick()

		}
		
	}

}

</script>

<style scoped>

</style>